/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, {useEffect,useState}  from "react"
import PropTypes from "prop-types"
import chroma from "chroma-js"

let textElement = React.createRef(); 
let interval;
function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}





class Intro extends React.Component {
  
  state = {
    left: -50,
    currentLeft:0,
    top: 0
  }

  constructor(props) {
     super(props);
  }
  componentDidMount() {
      interval = setInterval(() => {
        this.setState({left: this.state.left + 1 });
        if(this.state.left > 10) {

        this.setState({currentLeft: (this.state.left - 10) / 1000})
        }
        if(this.state.left > 1000) {
          this.props.onClick();
          clearInterval(interval);
        }
      },20)
      document.addEventListener('mousemove', (e) => {

        //  this.setState({left: e.pageX / getWindowDimensions().width, top: e.pageY/ getWindowDimensions().height});
      });
  }
  
  elementClick(left) {
    return () => {

    }
  }
  
  render() {
    let palette = chroma.scale(["rgb(255, 100, 100)", "rgb(60, 255, 100)", "rgb(255, 255, 100)"]); 
    let alpha = this.state.currentLeft > 0.75 ? 4 * (1 - this.state.currentLeft) : 1;
    let show =  this.state.currentLeft > 0 && this.state.currentLeft < 0.9 ? 1 : 0
    return(
  <div style={
        
        
    {"top":0,
    "bottom":0,
    "left":-this.state.currentLeft * (textElement?.current?.scrollWidth),
    "right":0,
    "background":`linear-gradient(0deg, ${palette(this.state.currentLeft).alpha(0.7).hex()}, ${palette(1 - this.state.currentLeft).alpha(0.7).hex()})`,
    "-webkit-text-stroke-width": "20px",
    "-webkit-text-stroke-color": "rgba(60, 230, 200,0.1)",
    "fontSize":"100vh",
    "position":"fixed",
    "opacity" : show,
    "whiteSpace":"nowrap",
    "transition": "opacity 1s ease",
    "cursor":"pointer",
    "zIndex":"1000"}}>
    <div onClick={this.props.onClick} ref={textElement} style={
      {
      "fontSize":"100vh",
      "-webkit-text-stroke-width": "3px",
      "-webkit-text-stroke-color": "black",
      "float":"left",
      "color":"rgba(60, 230, 200,0)"
  }}>Frances Keevil</div>
  </div>
  )}
}

Intro.defaultProps = {

}

Intro.propTypes = {

}

export default Intro

import React from "react"
import { StaticQuery, graphql, Link } from 'gatsby'
import Layout from "../components/layout"
import ImageRow from "../components/image-row"
import Break from "../components/break"
import Titles from "../components/titles"
import SEO from "../components/seo"
import Carousel from "../components/carousel"
import Intro from "../components/intro-2"
const gql_query = graphql`{
  homepage: craftAPI { entries(type: "homePage") {
    ... on CraftAPI_homePage_homePage_Entry {
      id
      images {
        ... on CraftAPI_artworks_Asset {
          id
          img
          filename
          url
        }
      }
      artist {
            ... on CraftAPI_artist_artist_Entry {
          id
          slug
          title
          artworks {
        ... on CraftAPI_artwork_artwork_Entry {
          id
          slug
          title
          artist {
            ... on CraftAPI_artist_artist_Entry {
              id
              slug
              title
            }
          }
          images {
            ... on CraftAPI_artworks_Asset {
              id
              url
              filename
              slug
              small: url(width: 500)
              img
            }
          }
        }
      }
    }
      }
      artworks {
        ... on CraftAPI_artwork_artwork_Entry {
          id
          slug
          title
          materials
          artist {
            ... on CraftAPI_artist_artist_Entry {
              id
              slug
              title
            }
          }
          imageThumbnail {
            ... on CraftAPI_artworks_Asset {
              id
              url
              filename
              medium: url(width: 1200)
              slug
              img
            }
          }
          images {
            ... on CraftAPI_artworks_Asset {
              id
              url
              filename
              slug
              small: url(width: 800)
              img
            }
          }
        }
      }
    }
  }
}
}`




const images = [{url:"/angela-goh-1.jpg", link:"/artwork-no-text", details:<div><div className="title">Angeline Goh</div><div className="materials">Being Different</div><div className="dimensions"><span>Wood, epoxy, clay figurines</span><span>125 x 31 x 31 cm</span>
<span>Edition of 10</span></div></div>},{url:"/angela-goh-3.jpg", link:"/artwork-no-text", details:<div><div className="title">Angeline Goh</div><div className="materials">Play Ball </div><div className="dimensions"><span>Clay</span><span>30 x 22 x 13 cm</span>
</div></div>}]

const titles = {"h2":"Featured Artist","h3":"Michael Vaynman"}

const artwork = (artworks) => {

    let artworks_rows = artworks.reduce((resultArray, item, index) => { 



      const chunkIndex = Math.floor(index/3)
      if(chunkIndex == 0 && index == 0) {
        resultArray[0] = [{
          type:"text", subtype:"highlight", text: <Link to={"/artworks"}>Browse the latest additions to our collection ⟶</Link>
        }]
        return resultArray;
      }
      if(!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = [] // start a new chunk
      }

      resultArray[chunkIndex].push({
      type:"image", 
      link:"/" + item.slug,
      url:item.imageThumbnail?.[0]?.medium ?? item.images?.[0]?.small,
      details:<div><div className="title">{item.artist[0].title}</div><div className="materials">{item.title}</div><div className="dimensions">{item.materials}
      </div></div>
      })

      return resultArray
      }, []).map((row) => <ImageRow images={row} /> )

  
    return(artworks_rows) 
  }

const featured_artist_artwork = (artworks) => {

  let artworks_rows = artworks.slice(0,2).reduce((resultArray, item, index) => { 



    const chunkIndex = Math.floor(index/3)
    
    if(!resultArray[chunkIndex]) {
    resultArray[chunkIndex] = [] // start a new chunk
    }

    resultArray[chunkIndex].push({
    type:"image", 
    link:"/" + item.slug,
    url:item.imageThumbnail?.[0]?.medium ?? item.images?.[0]?.small,
    details:<div><div className="title">{item.artist[0].title}</div><div className="materials">{item.title}</div><div className="dimensions">{item.materials}
    </div></div>
    })

    return resultArray
    }, []).map((row) => <ImageRow images={row} /> )


  return(artworks_rows) 
}
const featured_artist = (artist) => {
  if(artist) {
  return <>
  <Titles titles={{"h3":<Link to={artist.slug}>{artist.title}</Link>}} />
  {featured_artist_artwork(artist.artworks)}
</>
}
}
const news = (news) => {
  return <><Titles titles={{"h1":"News", "h3":"Read more updates ⟶"}} />
    <ImageRow images={[{type:"text", text: <div>04.02.2021  ⟶ We are delighted to announce that we will be representing French sculptor Francois Jaggi. First working with drawings, he then creates the originals and then casts them in bronze. After the casting he finely sands and polishes them using different patinas. Steel and Stainless steel are other mediums he uses, as well as wire, which he welds together returning all sculptures to their ideal form giving them light and graceful movement. </div>, span:"12"}] } /></>
}
const IndexPage = (props) => (
  <StaticQuery query={gql_query} render={ (data) => {
    
  let artworks_rows = artwork(data.homepage.entries[0].artworks)
  return <Layout hero={ <Carousel images={data.homepage.entries[0].images.map(image => image.url)} />} intro={true}>
    <SEO title="Home" />
    <div style={{ }}>
    
  
      {artworks_rows}

  <Titles titles={{"h1":data.homepage.entries[0].artist.length > 1? "Featured Artists" : "Featured Artist"}} />
      {data.homepage.entries[0].artist.map((artist) => featured_artist(artist))}
      
      
  

          

    </div>
  </Layout>}} />)


export default IndexPage
